import React from "react"
import { Link } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { TitleArrow } from "./arrow"

const ProjectList = ({ projects }) => {
  projects.sort((a, b) => a.frontmatter.index - b.frontmatter.index)
  return (
    <div className="mt-32 mb-48 last:mb-32">
      <h2 className="text-2xl sm:text-3xl font-medium mb-20">Featured Projects</h2>
      {projects.map(project => {
        return <Project project={project} />
      })}
    </div>
  )
}

const Project = ({ project }) => {
  const coverImage = project.frontmatter.coverImage
  const img = coverImage.childImageSharp ? getImage(coverImage) : null
  return (
    <article className="mb-12 sm:mb-20">
      <Link
        to={project.fields.slug}
        className="flex flex-wrap flex-row md:flex-nowrap group rounded-lg duration-300 hover:bg-bg_hover hover:bg-opacity-90 project-title"
      >
        <div className="md:flex-1 mr-0 md:mr-10 relative">
          {img ? (
            <div className="aspect-video relative overflow-hidden rounded-lg">
              <GatsbyImage
                className="absolute inset-0 w-full h-full"
                image={img}
                alt={project.frontmatter.title}
                layout="fullWidth"
              />
            </div>
          ) : (
            <img
              src={coverImage.publicURL}
              alt={project.frontmatter.title}
              className="w-full h-auto rounded-lg"
            />
          )}
        </div>
        <div className="md:flex-1 ml-0 md:ml-10 mr-0 md:mr-5 mt-4 md:mt-0 flex flex-col justify-center">
          <h3 className="text-black font-medium text-xl sm:text-3xl leading-normal sm:leading-normal">
            {project.frontmatter.title} <TitleArrow />
          </h3>
          <h4 className="text-black opacity-50 font-normal text-base sm:text-xl mt-2 sm:mt-4">
            {project.frontmatter.subtitle}
          </h4>
        </div>
      </Link>
    </article>
  )
}

export default ProjectList
