import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectList from "../components/project-list"
import WorkSnippets from "../components/work-snippets"
import { StaticImage } from "gatsby-plugin-image"

const HomePage = ({ data, location }) => {
  const projects = data.allMdx.nodes
  const workSnippets = [
    {
      image: "/work-snippets/shortcut.gif",
      title: "Keyboard Shortcuts App, 2024",
      description: "Designed shortcut customization to improve productivity and accessibility",
      url: "https://techcrunch.com/2024/04/04/chromeos-brings-customization-for-keyboard-shortcuts/"
    },
    {
      image: "/work-snippets/fast_pair.gif",
      title: "Fast Pair, 2023",
      description: "Enabled seamless device pairing with a single tap, in collaboration with the Android Fast Pair team.",
      url: "https://www.android.com/better-together/#:~:text=Connect%20your%20devices%20in%20one%20tap."
    },
    
    {
      image: "/work-snippets/instant_hotspot.gif",
      title: "Instant Hotspot Growth, 2024",
      description: "Led the growth experiment to improve engagement, making it easier for more users to stay connected on the go.",
      url: "https://support.google.com/pixelbook/answer/7504779?hl=en"
    },
    {
      image: "/work-snippets/peripherals.gif",
      title: "Device Settings, 2020-2023",
      description: "Drove Peripherals UX for a unified device connection and management experience",
    },
    {
      image: "/work-snippets/smart_lock.gif",
      title: "Smart Lock, 2021",
      description: "Streamlined the flow to make it easier to unlock Chromebook with your phone",
    },
    {
      image: "/work-snippets/bluetooth.png",
      title: "Bluetooth UX Redesign, 2020",
      description: "Created a Bluetooth connectivity framework that enhanced user satisfaction and reduced pairing friction",
    },
    {
      image: "/work-snippets/play.gif",
      title: "Google Play purchase, 2019",
      description: "Designed a cart abandonment experiment to boost purchases, and established a unified post-purchase UX experience.",
    },
    {
      image: "/work-snippets/zhihu_profile.jpg",
      title: "Zhihu Profile, 2016",
      description: "Overhauled the Profile page for Zhihu’s major product redesign, enhancing engagement for millions of users.",
      url: "https://www.zhihu.com/people/melodiezhang"
    }
  ]
  return (
    <Layout location={location}>
      <div className="flex flex-wrap sm:flex-nowrap items-center pt-0 sm:pt-20 pb-20 sm:pb-44">
        <StaticImage
          src="../images/profile_index.png"
          alt="Menghan's profile image"
          placeholder="none"
          className="w-44 h-44 flex-shrink-0"
        />
        <p className="font-normal text-2xl sm:text-4xl ml-0 sm:ml-20 leading-normal sm:leading-normal mr-10 sm:mr-20 mt-12 sm:mt-0">
          I'm <span className="font-semibold text-menghan">Menghan</span>,
          currently designing for {" "}
          ChromeOS at <span className="font-semibold">Google</span> to help people be more productive with seamless cross-device experiences.
        </p>
      </div>
      <div>
        <Seo title="Home" />
        <ProjectList projects={projects} />
        <WorkSnippets snippets={workSnippets} />
      </div>
    </Layout>
  )
}

export default HomePage

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: [frontmatter___index], order: ASC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          subtitle
          company
          index
          coverImage {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 800
                quality: 90
                formats: [AUTO, WEBP]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
    }
  }
`
