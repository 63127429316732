import React from "react"

const WorkSnippets = ({ snippets }) => {
  return (
    <div>
      <div className="w-screen relative left-[50%] right-[50%] ml-[-50vw] mr-[-50vw] bg-black py-32">
        <div className="max-w-4/5 xl:max-w-screen-lg mx-auto">
          <h2 className="text-2xl sm:text-3xl font-medium mb-20 text-white">More work</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-24">
            {snippets.map((snippet, index) => (
              <WorkSnippet key={index} snippet={snippet} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const WorkSnippet = ({ snippet }) => {
  const { image, title, description, url } = snippet
  
  return (
    <div className="block rounded-lg overflow-hidden">
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="block aspect-video relative overflow-hidden rounded-lg"
      >
        <img
          src={image}
          alt={title}
          className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
        />
      </a>
      <div className="mt-6">
        <h3 className="font-medium text-xl text-white">{title}</h3>
        <p className="text-white opacity-70 mt-3 text-base">{description}</p>
      </div>
    </div>
  )
}

export default WorkSnippets
